<template>
  <div class="mt-5">
    <h1 class="font-semibold text-xl mb-5 flex justify-between items-center">
      Users
    </h1>
    <div class="bg-white p-5 border border-gray-200 rounded-lg mb-5">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input v-model="form.email" placeholder="Email"></el-input>
        </el-col>
        <el-col :span="8"
          ><el-input v-model="form.password" placeholder="Пароль"></el-input
        ></el-col>

        <el-col :span="8">
          <el-select v-model="form.ms_id" filterable class="w-full">
            <el-option
              v-for="c in contragents"
              :key="c.id"
              :value="c.id"
              :label="c.name"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <div class="mt-3 text-right">
        <el-button @click="createUser" round plain type="primary"
          >Создать</el-button
        >
      </div>
    </div>
    <el-table border :data="users" stripe style="width: 100%">
      <el-table-column
        align="center"
        prop="id"
        width="50"
        label="ID"
      ></el-table-column>
      <el-table-column prop="email" label="Email"></el-table-column>
      <el-table-column prop="ms_id" label="Мой склад ID"> </el-table-column>
      <el-table-column prop="limit" label="Действия" align="right">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status === 0"
            @click="() => confirmDelete(scope.row.id)"
            icon="el-icon-delete"
            size="medium"
            type="danger"
            plain
            round
          ></el-button>
          <el-button
            v-else
            @click="() => restoreUser(scope.row.id)"
            icon="el-icon-refresh-left"
            title="Восстановить"
            size="medium"
            type="primary"
            plain
            round
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
  getUsers,
  createUser,
  deleteUser,
  restoreUser,
  getContragents,
} from "@/api/settings";

export default {
  name: "Users",
  data: () => ({
    users: [],
    contragents: [],

    form: {
      email: null,
      password: null,
      ms_id: null,
    },
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  async created() {
    if (this.user.id !== 1) {
      this.$router.push({ name: "products" });
      return;
    }
    await this.getUsers();
    await this.getContragents();
  },
  methods: {
    async getUsers() {
      this.users = await getUsers();
    },
    async getContragents() {
      this.contragents = await getContragents();
    },
    async createUser() {
      const result = await createUser(this.form);
      const message = `email: ${result.user.email}\npassword: ${result.user.password}`;
      this.$alert(message, "Пользователь создан", {
        confirmButtonText: "OK",
      });
    },
    confirmDelete(id) {
      this.$confirm("Удалить?", "Внимание", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        type: "warning",
      }).then(async () => {
        await this.deleteUser(id);
      });
    },
    async deleteUser(userId) {
      try {
        const result = await deleteUser(userId);
        if (result.success) {
          this.$message.success("Удален");
          await this.getUsers();
        } else {
          this.$message.error("Ошибка");
        }
      } catch (e) {
        console.log(e);
        this.$message.error("Ошибка ", e);
      }
    },

    async restoreUser(userId) {
      try {
        const result = await restoreUser(userId);
        if (result.success) {
          this.$message.success("Восстановлен");
          await this.getUsers();
        } else {
          this.$message.error("Ошибка");
        }
      } catch (e) {
        console.log(e);
        this.$message.error("Ошибка ", e);
      }
    },
  },
};
</script>

<style scoped></style>
