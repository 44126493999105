import apiClient from "@/api/apiClient";

export const getUsers = async () => {
  const result = await apiClient.get("/api/users");
  return result.data.users;
};

export const getContragents = async () => {
  const result = await apiClient.get("/api/contragents");
  return result.data.contragents;
};

export const createUser = async ({ email, password, ms_id }) => {
  const result = await apiClient.post("/api/users", {
    email,
    password,
    ms_id,
  });
  return result.data;
};

export const deleteUser = async (user_id) => {
  const result = await apiClient.post("/api/users", {
    user_id,
    action: "delete",
  });
  return result.data;
};

export const restoreUser = async (user_id) => {
  const result = await apiClient.post("/api/users", {
    user_id,
    action: "restore",
  });
  return result.data;
};
